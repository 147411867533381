import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// types
import { TContactUs, TContactUsState } from './../types/contact_us';
import { IDataType } from './../types/misc';

// Define the initial state using that type
const initialState: TContactUsState = {
	company_industries: [],
	prospective: [],
  industries_auto_complete: [],
}

export const contact_us = createSlice({
  name: 'contact_us',
  initialState,
  reducers: {
    setContactData: (state, action: PayloadAction<TContactUs>) => {
      state.prospective        = action.payload.prospective;
      state.company_industries = action.payload.company_industries;
    },
    setIndustries: (state, action: PayloadAction<IDataType[]>) => {
      state.industries_auto_complete = action.payload.map(item => ({label: item.name, value: item.name}))
    }
  },
})

export const { setContactData, setIndustries } = contact_us.actions

export default contact_us.reducer