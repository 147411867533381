import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// types
import { ICreateOrderType, IDataType, IDurationType } from '../types/misc';
import { ICreateOrderType as CreateOrder } from '../types/order';

// Define the initial state using that type
const initialState: ICreateOrderType  = {
  countries: [],
  expertise_levels:  [],
  job_types:  [],
  soft_skills:  [],
  technical_skills: [],
  states: [],
  genders: [],
  order_types: [],
  industries_auto_complete: [],
  duration: []
}

export const misc = createSlice({
  name: 'misc',
  initialState,
  reducers: {
    setUserElementaryData: (state, action: PayloadAction<CreateOrder>) => {
        state.countries = action.payload?.countries;
        state.expertise_levels = action.payload?.expertise_levels;
        state.job_types = action.payload?.job_types;
        state.soft_skills = action.payload?.soft_skills;
        state.technical_skills = action.payload?.technical_skills;
    },
    setState: (state, action: PayloadAction<IDataType[]>) => {
      state.states = action.payload;
    },
    setCountries: (state, action: PayloadAction<IDataType[]>) => {
      state.countries = action.payload;
    },
    setGender: (state, action: PayloadAction<IDataType[]>) => {
      state.genders = action.payload;
    },
    setOrderTypes: (state, action: PayloadAction<IDataType[]>) => {
      state.order_types = action.payload
    },
    setIndustries: (state, action: PayloadAction<IDataType[]>) => {
      state.industries_auto_complete = action.payload.map(item => ({label: item.name, value: item.name}))
    },
    setDuration: (state, action: PayloadAction<IDurationType[]>) => {
      state.duration = action.payload
    }
  },
})

export const { setUserElementaryData, setState, setGender, setCountries, setOrderTypes, setIndustries, setDuration } = misc.actions

export default misc.reducer