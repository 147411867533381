import React from 'react'

// page components
import Navbar from './nav_bar'

const Header: React.FC = () => {
  return (
    <div className='w-full'>
      <Navbar />
    </div>
  )
}

export default Header