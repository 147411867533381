import React from 'react'

// theme
import Landing from '../../themes'
import FaqTab from './misc/FaqTab'

const Faqs: React.FC = () => {

  return (
    <Landing>
      <section className='w-[100%] mt-10 md:mt-14 mb-5 mx-3 md:px-14 font-abel'>
        <div className='flex border-l-2 border-[#243677] mr-6'>
          <h3 className="text-3xl md:text-[40px] font-[400] md:ml-5 ml-3">Frequently Asked Questions</h3>
        </div>
      </section>

      <section className='font-abel bg-faq mb-5 bg-cover h-[250px] md:h-[454px] mx-3 md:mx-14 md:mb-10'>
        <div className="px-6 md:px-14 text-center text-[25px] md:text-[64px] bg-[#100F0F] bg-opacity-70 flex items-center justify-center h-[100%] text-[white]">
          Got Questions? We've Got Answers! Explore Our FAQ Section.
        </div>
      </section>

      <FaqTab />

    </Landing>
  )
}

export default Faqs