import { IAuthUser, UserType } from './../types/auth';
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import {AuthState} from '../types/auth'


// Define the initial state using that type
const initialState: AuthState = {
  user: null,
  token: localStorage.getItem('@serial'),
  loading: false,
}

export const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserType>) => {
      // state.user = action.payload?.user
    },
    setProfile: (state, action: PayloadAction<IAuthUser>) => {
      state.user = action.payload
    },
    setLoading: (state) => {
      state.loading = true;
    },
    closeLoading: (state) => {
      state.loading = false
    },
  },
})

export const { setUser, setProfile, setLoading, closeLoading } = auth.actions

export default auth.reducer