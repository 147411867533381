import React from "react";

// components
import Header from "./header";
import Footer from "./footer";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

// config
import config from "../config/env";
import { motion } from "framer-motion";

type LandingTheme = {
  className?: string;
  children?: React.ReactNode;
};

const Landing: React.FC<LandingTheme> = ({ children, className }) => {
  return (
    <div className="w-full">
      <Header />

      <motion.div
        className={`w-full justify-center ${className}`}
        initial={{ opacity: 0, width: 0 }}
        animate={{ opacity: 1, width: "100%" }}
        exit={{
          opacity: 0,
          x: window.innerWidth,
          transition: { duration: 0.2 },
        }}
      >
        {children}
      </motion.div>
      <Footer />
      <TawkMessengerReact
        propertyId={config.tawk_property_id}
        widgetId={config.tawk_widget_id}
      />
    </div>
  );
};

export default Landing;
