
import { Accordion } from "../../../components/elements/forms/Accordion";

const FaqClients = () => {
    return (
        <div className='accordion font-abel mb-20'>
            <Accordion title="What services does your recruitment firm offer?" >
                {/* <img src={db.arrow} className='chevron' alt='icon' /> */}
                We offer a comprehensive range of services
                designed to meet the diverse needs of both clients and job seekers. We leverage our
                experience and diversity in Executive Search, Contract or Permanent Staffing and Corporate
                Services to help your company evaluate and improve it’s end-to-end hiring process, giving you
                an edge in a fiercely competitive hiring market.
            </Accordion>

            <Accordion title="In what industries does your recruitment company specialize?">
                We specialize in various industries such as; Tech & Telco, Finance & Banking, Education, Hospitality, Construction,
                Public Sector, Energy, Maritime, Manufacturing, and more.
            </Accordion>
            <Accordion title="How do you ensure the confidentiality of our company's information during the recruitment process?">
                We prioritize the confidentiality of both clients and candidates. Our processes
                include strict confidentiality measures to protect sensitive information.
            </Accordion>
            <Accordion title="What is the typical timeframe for filling a job vacancy from the initial request to candidate
placement?">
                The timeline can vary based on the complexity of the position and the specific
                requirements. We work efficiently to present qualified candidates within a mutually
                agreed-upon timeframe. Ideally, it takes 2-3 weeks for permanent and contract staffing and
                4-6 weeks for executive search.
            </Accordion>
            <Accordion title="What are your fees and payment terms for your recruitment services?">
                Our fee structure is tailored to the services provided. We charge 15% of the annual gross salary for recruitment
                and we require 40% of the retained amount upfront.
            </Accordion>
            <Accordion title="What is your process for screening and evaluating candidates before presenting them to us?">
                Our candidate screening and evaluation process are thorough and designed to identify the
                best-fit candidates for your organization. It involves 1-3 stage interview stages depending on
                the level of the role. The first stage is an introductory interview with the HR Team. The second
                stage is an assessment tailored towards culture/ expertise needed, and the third stage is an
                interview with our C.E.O.
                Our goal is to present candidates who not only meet the technical requirements but also align
                with your company culture and values.
            </Accordion>
            <Accordion title="Can you assist with both permanent and temporary staffing needs?">
                25th and Staffing provides comprehensive recruitment services for both contract and permanent staffing. Our
                permanent and contract placement services provide a comprehensive solution to your
                recruitment needs, as we will work closely with you to understand your company’s culture and
                values.
            </Accordion>
            <Accordion title="Can you handle volume recruitments?">
                Yes, our recruitment firm is well-equipped to handle volume recruitments. We have a dedicated and experienced team that specializes in managing large-scale recruitment processes efficiently and effectively.
            </Accordion>
            <Accordion title="How do you handle situations where there is a shortage of qualified candidates for a particular
role?">
                We maintain a proactive talent pool and network of over 10,000 talents and partners,
                continually engaging with potential candidates even before specific roles are identified.
                We tailor recruitment strategies to attract passive candidates and those with transferable
                skills, ensuring a broader candidate pool. We also utilize advanced sourcing methods, including
                targeted outreach, networking events, and partnerships, to identify candidates with unique
                skill sets.
            </Accordion>
            <Accordion title="What geographic areas do you cover for your services?">We provide services across Africa, the
                US, the UK, and the Middle East. We have a global reach and the capability to support clients in
                various locations. Our extensive network and strategic partnerships allow us to effectively
                source, screen, and place candidates in diverse geographic areas, ensuring that we meet the
                unique talent acquisition needs of our clients.</Accordion>
        </div>
    );
}

export default FaqClients