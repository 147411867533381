import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import {IPayloadType, JobOrderType, OrderState} from '../types/order'
import {SimpleResponse} from '../types/misc'

// Define the initial state using that type
const initialState: OrderState = {
  orders: [],
  current_page: 1,
  last_page: 1,
  loading: false,
  order: null,
  position: [],
  auto_position: [],
  row_levels: [],
  row_types: [],
  skills: [],
  currencies: [],
  price_ranges: [],
}

export const order = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<IPayloadType>) => {
      state.orders       = action.payload.data
      state.current_page = action.payload?.current_page
      state.last_page    = action.payload?.last_page
    },
    setOrder: (state, action: PayloadAction<JobOrderType>) => {
      state.order = action.payload
    },
    setAutoSelectPosition: (state, action: PayloadAction<SimpleResponse[]>) => {
      state.auto_position = action.payload?.map(item => ({label: item.name, value: item.name}))
    },
    setPosition: (state, action: PayloadAction<SimpleResponse[]>) => {
      state.position = action.payload
    },
    setRoleLevel: (state, action: PayloadAction<SimpleResponse[]>) => {
      state.row_levels = action.payload
    },
    setRoleType: (state, action: PayloadAction<SimpleResponse[]>) => {
      state.row_types = action.payload;
    },
    setSkills: (state, action: PayloadAction<SimpleResponse[]>) => {
      state.skills = action.payload
    },
    setCurrencies: (state, action: PayloadAction<SimpleResponse[]>) => {
      state.currencies = action.payload
    },
    setPriceRange: (state, action: PayloadAction<SimpleResponse[]>) => {
      state.price_ranges = action.payload
    },
    setLoading: (state) => {
      state.loading = true
    },
    stopLoading: (state) => {
      state.loading = false;
    }
  },
})

export const { setOrders, setOrder, setAutoSelectPosition, setPosition, setRoleLevel, setRoleType, setSkills, setCurrencies, setPriceRange, setLoading, stopLoading } = order.actions

export default order.reducer