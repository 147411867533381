import * as React from "react";

// router
import { BrowserRouter } from "react-router-dom";

// auth and pages
// import { ComingSoon } from '../screens';
import AnimatedRoute from "./AnimatedRoute";

const MainNavigation: React.FC = () => {
  return (
    <BrowserRouter>
      <AnimatedRoute />
    </BrowserRouter>
  );
};

export default MainNavigation;
