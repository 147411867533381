
import { Accordion } from "../../../components/elements/forms/Accordion";

const FaqJobSeekers = () => {
    return (
        <div className='accordion font-abel mb-20'>
            <Accordion title="How can I contact your team if I have questions or need support during the job search process?">
                We encourage candidates to reach out to our dedicated support team by email at
                hr@25thandstaffing.com. Whether you have questions about specific job opportunities, need
                assistance navigating the application process, or require general support during your job search, our
                team is here to help. We aim to provide timely and comprehensive assistance to ensure a positive
                experience throughout your job search journey.
            </Accordion>
            <Accordion title=" How do you ensure confidentiality and data security for job seekers' information?">
                Protecting the
                confidentiality and security of job seekers' information is a top priority for us. Access to candidate
                information is restricted to authorized personnel only, and we adhere to strict confidentiality
                protocols. Additionally, we seek explicit consent before sharing any candidate details with potential
                employers, ensuring that your personal and professional information is treated with the utmost
                confidentiality and care.
            </Accordion>
            <Accordion title="How do I register with your recruitment agency as a job seeker?">
                We have created a stress-free process for job seekers to register on our platform. All you have to do is to Join here. We recommend submitting your updated resume and providing information about your skills, experience, and career
                goals. Our team will review your profile and reach out to discuss potential opportunities that match
                your qualifications and preferences.
            </Accordion>
            <Accordion title="Is there a fee for job seekers to use your services?">
                No, our services for job seekers are completely
                free of charge. We are committed to assisting candidates in their job search journey without imposing
                any fees. Our focus is on connecting qualified candidates with rewarding career opportunities, and we
                strive to make our services accessible to all job seekers.
            </Accordion>
            <Accordion title="Can you provide tips on resume writing and interview preparation?">
                Certainly! We offer valuable
                resources and guidance on resume writing and interview preparation. Visit our social media platforms
                for articles, templates, and tips to enhance your resume and excel in interviews.When our team is
                available for personalized coaching and advice to help you present your best self during the job
                application process, we will let you know.
            </Accordion>
            <Accordion title="Do you assist with negotiations and salary discussions between candidates and employers?">
                Yes, we actively assist candidates with negotiations and salary discussions. Our experienced team is
                well-versed in market trends and compensation benchmarks, and we work collaboratively with both
                candidates and employers to facilitate fair and mutually beneficial agreements. Our goal is to ensure
                that the negotiation process is transparent, and both parties are satisfied with the terms of
                employment.
            </Accordion>
            <Accordion title="Do you offer resume writing or editing services?">
                While we don't provide direct resume writing or
                editing services, we offer guidance and tips to help you create a compelling resume on our social
                media. Visit our social media platforms for articles, templates, and tips to enhance your resume and
                excel in interviews.
            </Accordion>
            <Accordion title="How long does it typically take to hear back from employers after submitting an application?">
                The timeframe for receiving feedback from employers can vary based on their internal processes. Typically,
                it can take between 1-4 weeks. However, we strive to keep candidates informed throughout the
                recruitment process. After you've submitted an application, we'll provide regular updates on the status
                of your application and coordinate with the employer to obtain timely feedback.
            </Accordion>
            <Accordion title="Do you offer career counseling or guidance services for job seekers?">
                We currently don't offer career counseling or guidance services, however, you can visit our social media for tips on resume writing and interview advice.
            </Accordion>
        </div>
    );
}

export default FaqJobSeekers