import { Tabs } from 'antd';
import useDashboard from "../hooks/useDashboard";

const onChange = (key: string) => {
    console.log(key);
};

const FaqTab = () => {
    const { items } = useDashboard();

    return (
        <>
            <article className='bg-[#FFFFFF] font-abel font-[400] px-6 md:py-10 md:px-14 pt-10 max-w-[1225px] m-auto'>
                <Tabs
                    onChange={onChange}
                    type="card"
                    items={items}
                    className='justify-center'
                />
            </article>
        </>
    )
}

export default FaqTab