import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

// types
import { MessageType } from './../types/message';

// Define the initial state using that type
const initialState: MessageType = {
  message: null,
  type: null,
  title: ""
}

export const message = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<MessageType>) => {
      state.message = action.payload.message
      state.type = action.payload.type
      state.title = action.payload?.title
    },
    clearMessage: (state: MessageType) => {
      state.message = null
      state.type    = null
      state.title = ""
    }
  },
})

export const { setMessage, clearMessage } = message.actions

export default message.reducer