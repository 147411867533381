import React from 'react'

type ButtonProps = {
    className?: string
    children: React.ReactNode
    onClick?: () => void
}

const Button: React.FC<ButtonProps> = ({children, className, onClick}) => {
  return (
    <button type="button" onClick={onClick} className={`flex items-center text-center font-abel py-2 px-6 md:ml-8 duration-500 ${className}`}>
      {children}
    </button>
  )
}

export default Button