// components
import { TabsProps } from "antd";
import FaqClients from "../misc/FaqClients";
import FaqJobSeekers from "../misc/FaqJobSeeker";

const useDashboard = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        "Clients"
      ),
      children: <FaqClients />,
    },
    {
      key: "2",
      label: (
        "Job Seekers"
      ),
      children: <FaqJobSeekers />,
    },
  ];

  return { items };
};

export default useDashboard;
