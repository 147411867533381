import { configureStore } from "@reduxjs/toolkit";

// environment
import env from "../config/env";

// store
import auth from "./slices/auth";
import message from "./slices/message";
import loading from "./slices/loading";
import order from "./slices/order";
import misc from "./slices/misc";
import contact_us from "./slices/contact_us";

export const store = configureStore({
  reducer: {
    auth,
    message,
    loading,
    order,
    misc,
    contact_us,
  },
  devTools: env.enviroment === "production" ? false : true,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
