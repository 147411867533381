import { createSlice } from '@reduxjs/toolkit'

// types
import { LoadingType } from './../types/loading';

// Define the initial state using that type
const initialState: LoadingType = {
  loading: false,
	update: false,
	more: false,
	delete: false,
}

export const loading = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true
    },
    stopLoading: (state) => {
      state.loading = false
    },
    setUpdateLoading: (state) => {
			state.update = true;
		},
		stopUpdateLoading: (state) => {
			state.update = false;
		},
		setMoreLoading: (state) => {
			state.update = true;
		},
		stopMoreLoading: (state) => {
			state.update = false;
		},
		setDeleteLoading: (state) => {
			state.update = true;
		},
		stopDeleteLoading: (state) => {
			state.update = false;
		}
  },
})

export const { setLoading, stopLoading, setUpdateLoading, stopUpdateLoading, setMoreLoading, stopMoreLoading, setDeleteLoading, stopDeleteLoading } = loading.actions

export default loading.reducer